import React, { useState } from 'react';
import styled from 'styled-components';

import Auth from 'components/Auth';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Assistance from 'components/Order/Assistance';
import TitleName from 'components/UI/Header/TitleName';

import { md } from 'utils/breakpoints';

const Container = styled.div`
  display: none;

  @media (max-width: ${md}) {
    display: block;
    margin: ${(props) => `${props.headerHeight}px`} auto 0;
  }
`;

const StyledHeader = styled(Header)`
  .switch-delivery-wrapper,
  .address-wrapper,
  .input-search-wrapper,
  .btn-cart-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }
`;

const QA = (props) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  return (
    <Container headerHeight={headerHeight}>
      <TitleName />
      <Auth />
      <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
        <StyledHeader title="詢問訂單" />
      </HeaderWrapper>
      <Assistance />
    </Container>
  );
};

export default QA;
